<template>
  <div class="pt-5 pb-16">
    <!-- Búsqueda -->
    <div class="container p-5 mx-auto">
      <div class="mb-5">
        <go-back-arrow @click="goBack">
          Volver
        </go-back-arrow>
      </div>

      <div class="font-bold text-xl">Resultados para: {{ getTitle }}</div>
      <div class="w-full h-1 border-b-2"></div>

      <!-- Resultados -->
      <div class="mt-2 flex flex-wrap gap-y-2 gap-x-2">
        <!-- Listado -->
        <div v-for="(item, index) in resultadosBusqueda" :key="index" :class="[
          { 'justify-start': item.image },
          { 'justify-center': !item.image },
          'px-2 py-1 border w-64 cursor-pointer flex items-center gap-x-2 bg-white hover:bg-gray-200',
        ]" @click="goToDespiece(item.id)">
          <div v-if="item.image" class="w-20 flex-shrink-0">
            <img :src="item.image" alt="" class="" />
          </div>
          <div class="text-xl">
            {{ item.text }}
          </div>
        </div>
      </div>

      <!-- No hay resultados -->
      <div v-if="resultadosBusqueda.length == 0 && !loading" class="w-full">
        No hay resultados para "{{ getTitle }}"
      </div>

      <!-- Loading -->
      <div v-if="loading">
        Cargando..
        <!-- TODO mejorar -->
      </div>
    </div>

    <!-- Whatsapp -->
    <transition name="fade">
      <div v-show="whatsappVisible" class="fixed bottom-10 right-10">
        <a :href="`https://wa.me/5491162183655?text=${textoWhatsapp}`" target="_blank" id="whatsapp-msg" style=""
          class="flex items-center gap-x-1">
          <div class="bg-white p-2 rounded border">Te podemos ayudar!</div>
          <img src="@/assets/whatsapp-icon.png" width="64" height="64" alt="Whatsapp" class="pngshadow" style="" />
        </a>
      </div>
    </transition>
  </div>
</template>


<script>
import router from "@/router/index";
import axios from "axios";
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

import {
  apiSearchMaquina,
  apiSearchRepuestos,
  apiSearchSubsections,
  apiSearchMarcas,
  apiGetSubsections,
  apiGetMarcas,
} from "@/utils/endpoints";
import {
  parseSearchProducts,
  parseSearchRepuestos,
  parseMarcas,
  parseSubsections,
} from "@/utils/parsers";

import GoBackArrow from "../components/elements/GoBackArrow.vue";

export default defineComponent({
  setup() {
    const route = useRoute();
    let searchTerm = ref("");
    searchTerm.value = route.params.search;
    let type = ref("");
    type.value = route.params.type;
    let loading = ref(true);
    let resultadosBusqueda = ref([]);
    const map = {
      maquina: {
        api: apiSearchMaquina,
        parser: parseSearchProducts,
      },
      repuesto: {
        api: apiSearchRepuestos,
        parser: parseSearchRepuestos,
      },
      subseccion: {
        api: apiSearchSubsections,
        parser: parseSearchProducts,
      },
      marca: {
        api: apiSearchMarcas,
        parser: parseSearchProducts,
      },
    };

    // GET Productos según type
    axios
      .get(map[type.value]["api"](searchTerm.value))
      .then((data) => {
        resultadosBusqueda.value = map[type.value]["parser"](data.data);
      })
      .then(() => (loading.value = false));

    // Para parsear los IDs de subsección y marca
    let titleReference = ref([]);
    if (type.value === "subseccion") {
      axios.get(apiGetSubsections()).then((data) => {
        titleReference.value = parseSubsections(data.data);
      });
    }
    if (type.value === "marca") {
      axios.get(apiGetMarcas()).then((data) => {
        titleReference.value = parseMarcas(data.data);
      });
    }


    // Se muestra el logo de Whatsapp después de un minuto
    const textoWhatsapp = `Hola! Te escribo desde la pantalla de búsqueda de Molamaq Repuestos`;
    let whatsappVisible = ref(false);
    setTimeout(() => {
      whatsappVisible.value = true;
    }, 60000);


    // Devuelve el nombre de las subsecciones y marcas
    const getTitle = computed(() => {
      if (type.value === "subseccion" || type.value === "marca") {
        if (titleReference.value.length === 0) {
          return "";
        }
        return titleReference.value.find((ref) => ref.furl == searchTerm.value)
          .text;
      }
      else {
        return searchTerm.value;
      }
    });


    const goToDespiece = (id) => {
      if (type.value == "repuesto") {
        router.push({ path: `/repuesto/${id}` });
      }
      else {
        router.push({ path: `/despiece/${id}` });
      }
    };

    const goBack = () => {
      router.push({name: 'Home'})
    }

    return {
      resultadosBusqueda,
      searchTerm,
      textoWhatsapp,
      whatsappVisible,
      loading,
      getTitle,
      goToDespiece,
      goBack
    };
  },
  components: { GoBackArrow }
});
</script>

<style scoped>
.grid-auto-col {
  grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
  grid-auto-rows: 1fr;
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>